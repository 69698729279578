.currentUser {
  display: flex;
  position: absolute;
  top: 47px;
  right: 45px;
  color: #fff;
}

.details {
  text-align: right;
  color: #3d4954;
  margin-right: 20px;
}

.details h1 {
  font-size: 1.375rem;
  margin: 0;
  font-family: "hk_groteskextrabold";
  font-weight: normal;
}

.details h2 {
  font-size: 1.125rem;
  margin: 0;
  font-weight: normal;
  font-family: "hk_groteskmedium";
  text-transform: capitalize;
}
