@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

@font-face {
  font-family: "hk_groteskbold";
  src: url("./assets/fonts/hkgrotesk-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/hkgrotesk-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hk_groteskbold_italic";
  src: url("./assets/fonts/hkgrotesk-bolditalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/hkgrotesk-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hk_groteskitalic";
  src: url("./assets/fonts/hkgrotesk-italic-webfont.woff2") format("woff2"),
    url("./assets/fonts/hkgrotesk-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hk_groteskmedium";
  src: url("./assets/fonts/hkgrotesk-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/hkgrotesk-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hk_groteskregular";
  src: url("./assets/fonts/hkgrotesk-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/hkgrotesk-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hk_grotesksemibold";
  src: url("./assets/fonts/hkgrotesk-semibold-webfont.woff2") format("woff2"),
    url("./assets/fonts/hkgrotesk-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hk_groteskextrabold";
  src: url("./assets/fonts/hkgrotesk-extrabold-webfont.woff2") format("woff2"),
    url("./assets/fonts/hkgrotesk-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dde9f5;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  width: 100%;
  min-height: 100vh;
}

footer.main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 62px;
  background-color: #65bbb5;
  color: #3d4954;
  font-size: 1rem;
  padding-left: 46px;
  padding-right: 46px;
}

@media print {
  .noPrint {
    display: none !important;
  }
}
