.activityToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3d4954;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.activityToolbar strong {
  padding-left: 15px;
  padding-right: 35px;
  text-transform: uppercase;
  color: #dde9f5;
  font-family: "hk_groteskbold";
  font-weight: normal;
  letter-spacing: 0.2em;
}
