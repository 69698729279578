.container {
  background-color: #fff;
  border-radius: 0px 0px 15px 15px;
  margin-bottom: 35px;
}

.map {
  width: 100%;
  height: 467px;
}

.townListing {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 33px;
  padding-right: 33px;
}

.mapLinks {
  padding-top: 18px;
  padding-bottom: 18px;
}

.mapLinks > button {
  margin-bottom: 10px;
  margin-right: 10px;
}

.infoWindow {
  text-align: center;
  font-size: 1.05rem;
}

.infoWindow h1 {
  margin: 0;
  font-family: "hk_groteskextrabold";
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.35rem;
  margin-bottom: 9px;
}

.infoWindow :global .info {
  margin-bottom: 10px;
}
