.loginForm {
  position: absolute;
  bottom: 88px;
  left: 125px;
  background-color: #eef2f5;
  width: 500px;
  min-height: 346px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  box-sizing: border-box;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 30px;
  padding-bottom: 48px;
}

.loginForm h1 {
  font-size: 1.75rem;
  color: #3d4954;
  font-family: "hk_groteskextrabold";
  font-weight: normal;
}

.form {
  margin-top: 25px;
}

.buttons {
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
  margin-top: 35px;
}

.buttons button {
  width: 100%;
}
