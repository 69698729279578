.breadcrumbs {
  margin-bottom: 30px;
}

.section {
  padding: 33px;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 40px;
  min-height: 250px;
}

.empty {
  margin-bottom: 25px;
}
