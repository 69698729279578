.townPage header {
  text-align: center;
  margin-bottom: 70px !important;
}

.townPage header h1 {
  font-size: 3.8rem !important;
  margin-bottom: 30px !important;
}

.breadcrumbs {
  margin-bottom: 30px;
}

.info {
  display: flex;
  justify-content: center;
}

.info span {
  display: block;
  padding: 15px;
  font-family: "hk_groteskbold";
  font-size: 1.25rem;
  background-color: #3d4954;
  color: #dde9f5;
  margin-right: 35px;
}

.info span:last-of-type {
  margin-right: 0;
}

.tabs > div {
  display: inline-block;
  padding: 15px 25px;
  border: 3px dashed #65bbb5;
  border-bottom: 0;
  font-size: 1.4rem;
  font-family: "hk_groteskextrabold";
  text-transform: uppercase;
  color: #65bbb5;
  cursor: pointer;
  text-decoration: none;
  margin-right: 20px;
}

.tabs :global .active {
  border: 3px solid #3d4954;
  border-bottom: 0;
  background-color: #3d4954;
  color: #f3dfac;
}

.townPage :global .tab {
  display: grid;
  grid-template-columns: 25% 70%;
  justify-content: space-between;
}

.townPage :global .tab {
  border: 3px solid #3d4954;
  padding: 25px;
}

.townPage :global .sidebar section {
  font-size: 1.2rem;
  line-height: 1.3;
  margin-bottom: 45px;
}

.townPage :global .sidebar section h1 {
  font-family: "hk_groteskbold";
  font-weight: normal;
  font-size: 1.45rem;
  border-bottom: 3px solid #182026;
  padding-bottom: 5px;
  margin: 0;
  margin-bottom: 10px;
}

.townPage :global .content {
  font-size: 1.2rem;
  line-height: 1.6;
}

.townPage :global .content ul {
  margin-top: 0;
}

.townPage :global .content h3 {
  position: relative;
  display: inline-block;
  background-color: #3d4954;
  font-size: 1.5rem;
  font-family: "hk_groteskextrabold";
  text-transform: uppercase;
  font-weight: normal;
  padding: 6px 1.75em 10px;
  margin: 0 0 0.5em;
  color: #fff;
  text-align: center;
}

.townPage :global .content h4 {
  font-family: "hk_groteskbold";
  color: #3d4954;
  font-size: 1.875rem;
  margin-bottom: 15px;
}

.townPage :global .content h3::before {
  content: "- -";
  position: absolute;
  margin-left: -35px;
  color: white;
}

.townPage :global .content h3::after {
  content: "- -";
  position: absolute;
  margin-left: 6px;
  color: white;
}

.townPage :global .content section {
  padding: 15px;
  margin-bottom: 35px;
}

.townPage :global .content section a {
  color: #fff;
  text-decoration: underline;
}

.townPage :global .content section > h5,
.townPage :global .content section > h1 {
  margin: 0;
  font-size: 1.425rem;
  margin-bottom: 20px;
}

.townPage :global section.detour {
  background-color: #0019e6;
  color: #fff;
  max-width: 500px;
}

.townPage :global section.agFacts {
  background: #6beda8;
  border: 6px dashed #f2e49d;
}

.townPage :global section.restStop {
  background: #0019e6;
  color: #fff;
}

.townPage :global section.travelLog {
  position: relative;
  border-width: 5px;
  border-style: double;
  border-color: #999999;
  background-color: rgb(153, 153, 153);
}

.townPage :global section.travelLog a {
  color: #00663c;
}

.townPage :global section.travelLog .journalLink {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.85rem;
  font-weight: bold;
  color: #00663c;
}

.townPage :global section.timelineBuilder {
  width: 220px;
  border: 2px solid #999999;
  background-color: rgb(153, 153, 153);
}

.townPage :global section.timelineBuilder a {
  color: #00663c;
}

.townPage :global section.researchDiscussLearn {
  background-color: #414143;
  color: #fff;
  text-align: center;
  max-width: 315px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 15px;
}

.townPage :global section.passport {
  background: #00663c;
  color: #fff;
  border-radius: 8px;
  border: 4px double #999999;
  max-width: 500px;
}

.townPage :global section.alignLeft,
.townPage :global section.alignleft {
  float: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.townPage :global section.alignRight,
.townPage :global section.alignright {
  float: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.clear {
  clear: both;
}
