.mapName {
  margin-bottom: 35px;
}

.mapName input {
  width: 100%;
  font-size: 2.2rem;
  background: transparent;
  appearance: none;
  outline: none;
  border: 0;
  display: block;
  box-sizing: border-box;
  border-bottom: 3px solid #3d4954;
}

.columns {
  display: grid;
  grid-template-columns: 25% 75%;
  justify-content: space-between;
}

.controls :global button,
.controls :global .bp3-button-group {
  width: 100%;
}

.designControls {
  margin-top: 35px;
}

.designControls :global .chrome-picker {
  width: 100% !important;
  margin-bottom: 15px;
}

.canvas :global .canvas-container {
  margin: auto;
}
