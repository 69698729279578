.articles {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 40px;
}

.buttons {
  text-align: center;
}

.buttons button {
  display: inline-block;
}

.article header {
  margin-bottom: 15px;
}

.article header h1 {
  font-size: 1.6rem;
  color: #3d4954;
  margin: 0;
  margin-bottom: 5px;
}

.article header h1 a {
  color: #3d4954;
}

.article p {
  font-size: 1rem;
  line-height: 1.4;
}

.postDate {
  font-weight: normal;
  font-style: italic;
  font-size: 0.875rem;
}

.readMore {
  color: #eb0808;
  font-size: 1rem;
}

.empty {
  grid-column: 1 / -1;
}
