.articleTeaser {
  margin-bottom: 35px;
}

.articleTeaser header h1 {
  font-size: 2rem;
  margin: 0;
  color: #3d4954;
  margin-bottom: 5px;
}

.articleTeaser header h1 a {
  color: #3d4954;
}

.articleTeaser header {
  margin-bottom: 15px;
}

.articleTeaser p {
  font-size: 1rem;
  line-height: 1.4;
}

.breadcrumbs {
  margin-bottom: 30px;
}

.article header {
  margin-bottom: 35px;
}

.article header h1 {
  font-size: 2.2rem !important;
  margin: 0;
  margin-bottom: 10px !important;
  color: #3d4954;
}

.postDate {
  color: #3d4954;
  font-size: 1rem;
  font-style: italic;
  font-weight: normal;
  font-size: 1rem;
}

.content {
  font-size: 1.1rem;
  line-height: 1.4;
}

.content :global .wp-block-embed-youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content :global .wp-block-embed-youtube > div {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.content :global .wp-block-embed-youtube > div::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.readMore {
  color: #eb0808;
  font-size: 1rem;
}

.empty {
  grid-column: 1 / -1;
}
