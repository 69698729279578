.buttonHeader {
  margin-top: 15px;
  margin-bottom: 35px;
}

.buttonHeader button {
  margin-right: 10px;
}

.studentGroup {
  margin-bottom: 55px;
  padding-bottom: 25px;
  border-bottom: 1px solid gray;
}

.studentGroup header {
  position: relative;
}

.studentGroup header button {
  position: absolute;
  top: 0;
  right: 0;
}

.studentGroup h2 {
  margin: 0;
  font-weight: normal;
  font-family: "hk_groteskextrabold";
  color: #3d4954;
  font-size: 1.5rem;
  margin-bottom: 15px;
}
