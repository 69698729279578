.menu {
  width: 100%;
  height: 100%;
  background-color: #3d4954;
}

.logo {
  margin: 0;
  margin: auto;
  width: 100px;
  margin-top: 36px;
  margin-bottom: 60px;
}

.navGroup {
  margin-bottom: 75px;
}

.navGroup h1 {
  font-size: 0.875rem;
  font-weight: normal;
  font-family: "hk_groteskextrabold";
  color: #f5f5f5;
  text-transform: uppercase;
  margin: 0;
  margin-left: 29px;
  margin-bottom: 10px;
}

.navGroup ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navGroup ul li {
  height: 55px;
  font-size: 1.125rem;
  color: #bdbdbd;
  font-family: "hk_grotesksemibold";
}

.navGroup ul li a {
  display: flex;
  align-items: center;
  padding-left: 28px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  color: #bdbdbd;
  text-decoration: none;
}

.navGroup ul li img {
  vertical-align: middle;
  margin-right: 20px;
  opacity: 0.5;
}

.navGroup ul li a:hover,
.active {
  color: #fff !important;
  background-color: #53616d;
}

.navGroup ul li a:hover img,
.active img {
  opacity: 1 !important;
}
