.timelineName {
  margin-bottom: 35px;
}

.timelineName input {
  width: 100%;
  font-size: 2.2rem;
  background: transparent;
  appearance: none;
  outline: none;
  border: 0;
  display: block;
  box-sizing: border-box;
  border-bottom: 3px solid #3d4954;
}

.timelineForm {
  margin-top: 20px;
}

.timelineForm > h2 {
  font-family: "hk_groteskextrabold";
  text-transform: uppercase;
  font-size: 1.25rem;
  color: #3d4954;
}

.columns {
  display: grid;
  grid-template-columns: 30% 60%;
  justify-content: space-between;
}

@media print {
  .columns {
    display: block;
  }
}

.events {
  margin-top: 65px;
}

.event {
  border-bottom: 2px dashed #3d4954;
  margin-bottom: 30px;
}

.event:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}

.activeTimeline {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  border-bottom: 5px solid #3d4954;
}

.activeTimeline h1 {
  text-align: center;
  font-family: "hk_groteskextrabold";
  font-weight: normal;
  border-bottom: 5px solid #3d4954;
  color: #3d4954;
  padding-bottom: 5px;
}

.timeline {
  position: relative;
  display: flex;
  width: 100%;
}

.bar {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 5px;
  height: 100%;
  background-color: #8fb6da;
  border: 3px dashed #3d4954;
}

.timelineEvents {
  width: 100%;
}

.timelineEvents ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 55px;
  padding-bottom: 55px;
}

.timelineEvents :global ul li {
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 35px;
  min-height: 50px;
}

.timelineEvents :global ul li .inner {
  position: relative;
  width: 50%;
  display: flex;
}

.timelineEvents :global ul li .inner .text {
  position: relative;
  width: 62%;
  background-color: #dde9f5;
  z-index: 5;
}

.timelineEvents :global ul li .inner .year {
  position: relative;
  width: 23%;
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;

  background-color: #dde9f5;
  z-index: 5;
}

.timelineEvents :global ul li .dot {
  position: absolute;
  top: 3px;
  right: -14px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 3px solid #8fb6da;
  background-color: #fff;
  display: block;
  z-index: 10;
}

.timelineEvents :global ul li .inner span.bar {
  position: absolute;
  top: 10px;
  height: 5px;
  width: 100%;
  background-color: #8fb6da;
  z-index: 1;
}

.timelineEvents :global ul li:nth-child(even) .inner {
  float: right;
  flex-direction: row-reverse;
}

.timelineEvents :global ul li:nth-child(even) .inner span.dot {
  right: auto;
  left: -14px;
}

.timelineEvents :global ul li:nth-child(even):after {
  content: "";
  display: block;
  clear: both;
}

.timelineEvents :global ul li:nth-child(even) .dot {
  right: auto;
  left: -14px;
}
