.article {
  margin-bottom: 70px !important;
}

.article header {
  text-align: center;
  margin-bottom: 60px;
}

.article header h1 {
  font-size: 3.8rem !important;
  margin: 0;
  margin-bottom: 10px !important;
}
.article header h2 {
  font-size: 1.8rem !important;
  margin: 0;
  margin-bottom: 10px;
  color: #3d4954;
}

.postDate {
  font-size: 1.2rem;
  margin-bottom: 30px !important;
  color: #3d4954;

  font-style: italic;
  font-weight: normal;
}

.breadcrumbs {
  margin-bottom: 30px;
}

.content {
  font-size: 1.2rem;
  line-height: 1.6;
  padding: 33px;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 40px;
  min-height: 250px;
}
