.dashboard {
  width: 100%;
  min-height: 95.5vh;
  height: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 20% 80%;
  background: url("../assets/bg2.jpg"), #eef2f5;
  background-blend-mode: multiply, normal;
  background-size: 100% auto;
}

.subPage {
  padding: 40px;
  padding-top: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(237, 242, 245, 0.5);
}

.subPage > div > header h1 {
  margin: 0;
  color: #3d4954;
  font-size: 2.625rem;
  font-family: "hk_groteskbold";
  font-weight: normal;
  margin-bottom: 40px;
}

.subPage :global section.section {
  padding: 33px;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 40px;
  min-height: 250px;
}

.subPage :global section.section > h1 {
  margin: 0;
  font-weight: normal;
  font-family: "hk_groteskextrabold";
  color: #3d4954;
  font-size: 1.5rem;
}
