.columns {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 68% 30%;
  margin: auto;
}

.activityBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  padding: 10px;

  color: #3d4954;
  font-family: "hk_groteskbold";
  text-decoration: none;
}

.activityBtn:hover {
  color: #65bbb5;
  text-decoration: none;
}

.activityBtn h2 {
  text-align: right;
  margin: 0;
  font-size: 1.5rem;
  padding-right: 15px;
  font-weight: normal;
}

.activityBtn span {
  display: block;
  font-size: 2.2rem;
}

.activityBtn img {
  height: 90px;
  width: auto;
}

.activityBtn.travelGuide {
  cursor: pointer;
}

.activityBtn.travelGuide img {
  width: 150px;
  height: auto;
}

.sidebarCol > a {
  margin-bottom: 20px;
}
