.buttons button {
  margin-right: 15px;
}

.postList {
  padding-top: 25px;
}

.post {
  position: relative;
  padding: 30px;
  border: 3px solid #3d4954;
  width: 75%;
  margin: auto;
  margin-bottom: 35px;
}

.post h1 {
  margin: 0;
  margin-bottom: 35px;
  font-size: 2.4rem !important;
  font-family: "hk_groteskbold";
  font-weight: normal;
}

.post :global header .buttons {
  position: absolute;
  top: 40px;
  right: 30px;
}

.postContent {
  font-size: 1.2rem;
  line-height: 1.6;
}

@media print {
  .post {
    width: 100%;
  }

  .post h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .postContent {
    font-size: 1rem;
  }
}
