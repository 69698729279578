.header {
  position: relative;
  padding-top: 33px;
  margin-bottom: 40px;
  z-index: 10;
}

.logo {
  margin: 0;
  padding: 0;
  margin-left: 45px;
  margin-top: 20px;
}

.dashboard {
  position: relative;
  width: 100%;
  min-height: 95vh;
  background: url("../assets/bg2.jpg"), #eef2f5;
  background-blend-mode: multiply, normal;
  background-size: 100% auto;
}

.dashboard > span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eef2f5;
  opacity: 0.65;
  z-index: 1;
}

.subPage {
  position: relative;
  padding: 46px;
  padding-top: 0;
  z-index: 10;
}

.subPage > div > header {
  margin-bottom: 35px;
}

.subPage > div > header h1 {
  font-family: "hk_groteskbold";
  font-size: 2.625rem;
  color: #3d4954;
  margin: 0;
  font-weight: normal;
}
