.loginPage {
  position: absolute;
  background-image: url("../assets/bg1.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
}

.logo {
  position: absolute;
  top: 32px;
  right: 45px;
  margin: 0;
  font-size: 1rem;
}

.logo small {
  position: absolute;
  bottom: -5px;
  left: 120px;
  font-size: 1rem;
  color: #3d4954;
  font-family: "hk_groteskbold";
  font-weight: normal;
}
