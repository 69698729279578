.agHistoryNews {
  padding: 33px;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 40px;
  min-height: 250px;
}

.agHistoryNews :global .buttons {
  text-align: center;
}

.agHistoryNews :global .buttons button {
  display: inline-block;
}

.agHistoryNews > h1 {
  margin: 0;
  font-weight: normal;
  font-family: "hk_groteskextrabold";
  color: #3d4954;
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.readMore {
  color: #eb0808;
  font-size: 1rem;
}

.articleTeaser {
  margin-bottom: 35px;
}

.articleTeaser header {
  margin-bottom: 25px;
}

.articleTeaser header h1 {
  font-size: 2rem;
  margin: 0;
  color: #3d4954;
}

.articleTeaser header h1 a {
  color: #3d4954;
}

.postDate {
  color: #3d4954;
  font-size: 1rem;
  font-style: italic;
  font-weight: normal;
  font-size: 1rem;
}

.teaser {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 14px;
}

.empty {
  margin-bottom: 25px;
}
