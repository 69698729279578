.fileBrowser {
  margin-top: 45px;
}

.fileBrowser header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  padding: 10px;
  background-color: #3d4954;
  color: #fff;
  border-radius: 10px;
}

.fileBrowser header button {
  vertical-align: middle;
}

.fileBrowser header h1 {
  position: relative;
  top: -2px;
  font-family: "hk_groteskextrabold";
  font-weight: normal;
  font-size: 1.25rem;
  display: inline-block;
  margin: 0;
  margin-left: 20px;
  vertical-align: middle;
  text-transform: uppercase;
}
